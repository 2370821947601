import {
  Box,
  Card,
  Fab,
  Input,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataItemType } from "../../pages/Landing";

const DataOrderList = [
  {
    label: "Catatan Order",
    key: "cator",
    placeholder: "contoh: Bundling: Lauk Tongkol Suwir",
  },
  { label: "Nama", key: "nama", placeholder: "ketik nama anda" },
  { label: "No WA", key: "nowa", placeholder: "ketik nomer whatsapp anda" },
  { label: "Email", key: "angkatan", placeholder: "contoh: email@gmail.com" },
  {
    label: "Alamat Antar",
    key: "rukel",
    placeholder: "contoh: Kampus PPM / Kelas 501",
  },
  { label: "Jam Antar", key: "jaman", placeholder: "contoh: 10.00" },
];

const InputStyled = styled(Input)(({ theme }) => ({
  fontSize: "1.25rem",
  width: "350px",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

function DataOrderCard({
  totalPrice,
  dataCheckout,
}: {
  totalPrice: string;
  dataCheckout: Array<DataItemType>;
}) {
  const matches = useMediaQuery("(min-width:500px)");
  const navigate = useNavigate();
  const [catatan, setCatatan] = useState("");
  const [name, setName] = useState("");
  const [noWA, setNoWA] = useState("");
  const [angkatan, setAngkatan] = useState("");
  const [antar, setAntar] = useState("");
  const [jam, setJam] = useState("");

  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log("e", e.target.value);
    if (e.target.checkValidity()) {
      navigate("/payment", {
        state: {
          totalPrice: totalPrice,
          data: {
            name,
            noWA,
            angkatan,
            antar,
            jam,
            catatan,
          },
          dataCheckout: dataCheckout,
        },
      });
    } else {
      alert("Form is invalid! Please check the fields...");
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", marginTop: "5%" }}>
      <Card
        sx={{
          backgroundColor: "white",
          paddingX: 4,
          paddingY: 2,
          border: "1px solid #023668",
          width: "100%",
          maxWidth: "800px",
        }}
      >
        <Typography variant="h3" textAlign={"center"} color="text">
          Data Order
        </Typography>
        <Typography
          variant="body2"
          textAlign={"center"}
          color="rgba(0,0,0,0.8)"
          style={{ marginBottom: 4 }}
        >
          Pastikan mengisi dengan benar
        </Typography>
        <Typography variant="body2" textAlign={"center"} color="red">
          *Alamat diluar PPM dikenakan Ongkir
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate>
          <List sx={{ width: "100%" }}>
            {DataOrderList?.map((value, idx) =>
              matches ? (
                <ListItem
                  key={idx.toString()}
                  disableGutters
                  secondaryAction={
                    <InputStyled
                      required={value?.key !== "cator"}
                      placeholder={value.placeholder}
                      multiline
                      size="small"
                      onChange={(t) => {
                        const text = t.target.value;
                        switch (value?.key) {
                          case "cator":
                            setCatatan(text);
                            break;
                          case "nama":
                            setName(text);
                            break;
                          case "nowa":
                            setNoWA(text);
                            break;
                          case "angkatan":
                            setAngkatan(text);
                            break;
                          case "rukel":
                            setAntar(text);
                            break;
                          case "jaman":
                            setJam(text);
                            break;
                          default:
                            break;
                        }
                      }}
                    />
                  }
                >
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography variant="body2" color="text">
                        {value?.label}
                      </Typography>
                    }
                  />
                </ListItem>
              ) : (
                <TextField
                  placeholder={value.placeholder}
                  multiline
                  label={value.label}
                  variant="standard"
                  size="small"
                  sx={{ marginBottom: 1, width: "100%" }}
                  inputProps={{ style: { fontSize: 22 } }}
                  onChange={(t) => {
                    const text = t.target.value;
                    switch (value?.key) {
                      case "cator":
                        setCatatan(text);
                        break;
                      case "nama":
                        setName(text);
                        break;
                      case "nowa":
                        setNoWA(text);
                        break;
                      case "angkatan":
                        setAngkatan(text);
                        break;
                      case "rukel":
                        setAntar(text);
                        break;
                      case "jaman":
                        setJam(text);
                        break;
                      default:
                        break;
                    }
                  }}
                  InputLabelProps={{ style: { fontSize: 18 } }}
                  required={value?.key !== "cator"}
                />
              )
            )}
          </List>
          <Fab
            type="submit"
            variant="extended"
            // size="medium"
            color={undefined}
            sx={{
              color: "white",
              backgroundColor: "#F6C82B",
              padding: 1,
              fontSize: "0.85rem",
              zIndex: 9999,
              position: "fixed",
              width: "60%",
              left: "20%",
              bottom: "2%",
              borderRadius: 3,
              "&:focus": {
                backgroundColor: "#F6C82B",
              },
              "&:hover": {
                backgroundColor: "#F6C82B",
              },
            }}
          >
            {/* <DiningIcon sx={{ mr: 1 }} /> */}
            Bayar
          </Fab>
        </Box>
      </Card>
    </div>
  );
}

export default DataOrderCard;
