import headerIllus from "./header_illus.png";
import logoJajansedep from "./logo_jajansedep.png";
import kopiKaluen from "./kopikaluen.jpeg";
import peachgumReady from "./peachgun_ready.jpeg";
import peachgumPack from "./peachgum_pack.jpeg";
import jamuSaha from "./jamu_saha.png";
import yume from "./yume.png";
import puding from "./puding.png";
import laukParu from "./lauk_paru_sambal_ijo.jpeg";
import laukTongkol from "./lauk_tongkol_suwir.jpeg";
import laukCumi from "./lauk_cumi.jpeg";
import laukAyam from "./lauk_ayam_woku.jpeg";
import laukDaun from "./lauk_daun_pepaya.jpeg";
import laukUdang from "./lauk_udang_kentang.jpeg";
import sambel from "./sambel.jpeg";
import qris from "./qris.jpeg";
import bundling from "./bundling.jpeg";

const images = {
  headerIllus,
  kopiKaluen,
  peachgumReady,
  peachgumPack,
  logoJajansedep,
  jamuSaha,
  yume,
  puding,
  laukAyam,
  laukCumi,
  laukDaun,
  laukParu,
  laukTongkol,
  laukUdang,
  sambel,
  qris,
  bundling,
};

export default images;
