import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Alert,
  Card,
  Collapse,
  Container,
  Fab,
  Link,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import emailjs from "@emailjs/browser";
import { Update, createBrowserHistory } from "history";
import { DataItemType } from "../Landing";
import images from "../../assets/images";

const history = createBrowserHistory();

function Payment() {
  const location = useLocation();
  const navigate = useNavigate();
  const totalPrice = location?.state?.totalPrice;
  const dataCheckout: Array<DataItemType> = location?.state?.dataCheckout;
  const data = location?.state?.data;
  const [open, setOpen] = useState(false);
  const [stringyDataCO, setStringyDataCO] = useState("");
  const [copied, setCopied] = useState("");
  console.log("totalPrice", totalPrice);
  console.log("data", data);
  const numberPrice = totalPrice.slice(2);
  const matches = useMediaQuery("(min-width:500px)");

  useEffect(() => {
    emailjs.init({
      publicKey: process.env.REACT_APP_EMAIL_KEY,
      // Do not allow headless browsers
      blockHeadless: true,
      limitRate: {
        // Allow 1 request per 10s
        throttle: 10000,
      },
    });
  }, []);

  useEffect(() => {
    let stringy = "";
    dataCheckout?.forEach((itm) => {
      stringy = stringy + `${itm?.title}\n`;
    });
    setStringyDataCO(stringy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container
      maxWidth={"lg"}
      sx={{
        backgroundColor: "white",
        paddingBottom: "80px",
        paddingTop: "2%",
      }}
    >
      <Collapse in={open}>
        <Alert sx={{ mb: 2 }} variant="filled" severity="success">
          Terimkasih telah melakukan pembayaran, pesanan anda sudah diterima,
          jika ingin konfirmasi lebih lanjut silakan whatsapp kami di
          <Link
            style={{ color: "#210af0" }}
            rel="noopener noreferrer"
            href="https://wa.me/6285281454300"
            target="_blank"
          >
            {" 085281454300 "}
          </Link>
          !
        </Alert>
      </Collapse>
      <div
        style={{ display: "flex", justifyContent: "center", paddingTop: "6%" }}
      >
        <Card
          sx={{
            backgroundColor: "white",
            paddingX: 4,
            paddingY: 2,
            border: "1px solid #023668",
            width: "100%",
            maxWidth: "800px",
          }}
        >
          <Typography variant="h3" textAlign={"center"} color="text">
            Pembayaran
          </Typography>
          <Typography
            variant="body2"
            textAlign={"center"}
            color="rgba(0,0,0,0.8)"
            style={{ marginBottom: 8 }}
          >
            Silahkan bayar ke QRIS berikut
          </Typography>
          <Typography
            variant="body2"
            textAlign={"center"}
            color="primary"
            style={{ marginBottom: 12 }}
          >
            Scan dari QRIS M-Banking kamu atau Screenshoot lalu upload di QRIS
            M-Banking kamu
          </Typography>
          <Typography
            variant="body2"
            textAlign={"center"}
            color="#d10000"
            style={{ marginBottom: 12 }}
          >
            !! PASTIKAN KLIK
            <Typography display={"inline"} variant="h5">
              {` SUDAH BAYAR `}
            </Typography>
            SETELAH PEMBAYARAN BERHASIL DILAKUKAN !!
          </Typography>
          <List sx={{ width: "60%", paddingLeft: "20%" }}>
            {/* <ListItem
              disableGutters
              secondaryAction={
                <ListItemText
                  disableTypography
                  primary={
                    <Link
                      component="button"
                      variant="h5"
                      onClick={() => {
                        navigator?.clipboard?.writeText("2120354224");
                        setCopied(`Copied "2120354224"`);
                      }}
                    >
                      Copy
                    </Link>
                  }
                />
              }
            >
              <ListItemText
                disableTypography
                primary={
                  <>
                    <Typography variant="h5" color="text">
                      2120354224
                    </Typography>
                    <Typography variant="h5" color="text">
                      BCA / Hafi Leo P
                    </Typography>
                  </>
                }
              />
            </ListItem> */}
            <ListItem
              disableGutters
              secondaryAction={
                <ListItemText
                  disableTypography
                  primary={
                    <Link
                      component="button"
                      variant="body2"
                      onClick={() => {
                        navigator?.clipboard?.writeText(numberPrice);
                        setCopied(`Copied "${numberPrice}"`);
                      }}
                    >
                      Copy
                    </Link>
                  }
                />
              }
            >
              <ListItemText
                disableTypography
                primary={
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "700" }}
                    color="text"
                  >
                    {totalPrice}
                  </Typography>
                }
              />
            </ListItem>
          </List>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "6%",
            }}
          >
            <img
              src={images.qris}
              alt="qris"
              loading="lazy"
              style={{
                width: matches ? "50%" : "90%",
                height: "auto",
                padding: 0,
                margin: 0,
              }}
            />
          </div>
        </Card>
      </div>
      <Fab
        onClick={() => {
          setOpen(true);
          emailjs.send("service_4bv6q5w", "template_wis1n5o", {
            from_name: `${data?.name}`,
            message: `No WA: ${data?.noWA}\nKelas: ${data?.angkatan}\nAntar: ${data?.antar}\nJam: ${data?.jam}\n\nCatatan Order: ${data?.catatan}\nOrder:\n${stringyDataCO}\n\nTOTAL: ${totalPrice}`,
          });
          history.listen((update: Update) => {
            if (update.action === "POP") navigate("/");
          });
        }}
        variant="extended"
        disabled={open}
        color={undefined}
        sx={{
          color: "white",
          backgroundColor: "#F6C82B",
          padding: 1,
          fontSize: "0.85rem",
          zIndex: 9999,
          position: "fixed",
          width: "60%",
          left: "20%",
          bottom: "5%",
          borderRadius: 3,
          "&:focus": {
            backgroundColor: "#F6C82B",
          },
          "&:hover": {
            backgroundColor: "#F6C82B",
          },
        }}
      >
        {/* <DiningIcon sx={{ mr: 1 }} /> */}
        Sudah Bayar
      </Fab>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!copied}
        onClose={() => {
          setCopied("");
        }}
        message={copied}
      />
    </Container>
  );
}

export default Payment;
