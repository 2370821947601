import React from "react";
import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Landing from "./pages/Landing";
import Checkout from "./pages/Checkout";
import { Box } from "@mui/material";
import Payment from "./pages/Payment";

const theme = createTheme({
  palette: {
    primary: {
      main: "#023668",
    },
    secondary: {
      main: "#F6C82B",
    },
    text: {
      primary: "#000000",
    },
  },
});

theme.typography.h1 = {
  fontSize: "5rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "3.5rem",
  },
};

theme.typography.h2 = {
  fontSize: "3rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "2rem",
  },
};

theme.typography.h3 = {
  fontSize: "2rem",
  [theme.breakpoints.down("lg")]: {
    fontSize: "1.5rem",
  },
};

theme.typography.h5 = {
  fontSize: "1.25rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.5rem",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "0.95rem",
  },
};

theme.typography.body1 = {
  fontSize: "1.75rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
  },
};

theme.typography.body2 = {
  fontSize: "1.25rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.5rem",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "0.95rem",
  },
};

theme.typography.h6 = {
  fontSize: "1.35rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.75rem",
  },
  [theme.breakpoints.down("lg")]: {
    fontSize: "1rem",
  },
};

theme.typography.caption = {
  fontSize: "1rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.75rem",
  },
};

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box
        minHeight={"100vh"}
        display="flex"
        sx={{
          backgroundColor: "#d3dedc",
        }}
      >
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/payment" element={<Payment />} />
          </Routes>
        </BrowserRouter>
      </Box>
    </ThemeProvider>
  );
};

export default App;
