import React from "react";
import { Box, Button, styled, Typography, useMediaQuery } from "@mui/material";
import images from "../../assets/images";

const Header = ({
  onPressBuy,
  onPressAboutus,
}: {
  onPressBuy: () => void;
  onPressAboutus: () => void;
}) => {
  const matches = useMediaQuery("(min-width:500px)");

  const CustomBox = styled(Box)(({ theme }) => ({
    minHeight: "50vh",
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(2),
    paddingTop: theme.spacing(6),
    backgroundColor: "white",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      paddingBottom: theme.spacing(16),
    },
  }));

  const BoxText = styled(Box)(({ theme }) => ({
    flex: "1.5",
    paddingLeft: theme.spacing(8),
    [theme.breakpoints.down("md")]: {
      flex: "2",
      textAlign: "center",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }));

  return (
    <CustomBox component="header">
      <BoxText component="section">
        <div>
          <img
            src={images.logoJajansedep}
            alt="abc"
            height="auto"
            width="90%"
            id="image-section"
          />
        </div>
        <Typography
          variant="h3"
          color="primary"
          sx={{
            textAlign: "center",
            fontWeight: 700,
            marginTop: "2vh",
          }}
        >
          Website Order
        </Typography>

        <Typography
          variant="body1"
          component="p"
          sx={{
            py: 1,
            lineHeight: 1.6,
          }}
        >
          Kawan JAJAN-nya PPM Mates!
        </Typography>

        <Typography
          variant="caption"
          component="p"
          sx={{
            lineHeight: 1,
            color: "red",
          }}
          textAlign={"left"}
          style={{ wordWrap: "break-word", display: "inline-block" }}
        >
          <p />
          Sesi Pre-Order Promo Bundling (5 Agustus 2024 s/d Jam 12 siang Jum'at
          9 Agustus 2024)
          <br />
          <br />
          Note:
          <br />
          Orderan dikirim H+2
          <br />
          Orderan hari Jum'a tanggal 9 Agustus, dikirim Sabtu tanggal 10
          Agustus.
          <br />
          <br />
          Jangan sampai ketinggalan, pre-order sekarang dan ijinkan SEDEPEDES
          menjadi bagian dari kisah lezatmu!
          {/* <br />
          Thank You */}
        </Typography>

        <Box>
          <Button
            onClick={onPressBuy}
            variant="contained"
            sx={{
              mr: 2,
              px: 4,
              py: 1,
              fontSize: "0.9rem",
              textTransform: "capitalize",
              borderColor: "#14192d",
              color: "#ffffff",
              backgroundColor: "primary",
              marginTop: "3vh",
            }}
          >
            Jajan Sekarang
          </Button>
        </Box>
        <Box>
          <Button
            onClick={onPressAboutus}
            variant="outlined"
            sx={{
              mr: 2,
              px: 4,
              py: 1,
              fontSize: "0.9rem",
              textTransform: "capitalize",
              borderColor: "#14192d",
              color: "primary",
              marginTop: "1.5vh",
            }}
          >
            About Us
          </Button>
        </Box>
      </BoxText>

      <Box
        sx={(theme) => ({
          [theme.breakpoints.down("md")]: {
            flex: "1",
            paddingTop: "30px",
            alignSelf: "center",
          },
          [theme.breakpoints.up("md")]: {
            flex: "2",
            alignSelf: "flex-end",
          },
        })}
      >
        <img
          src={images.headerIllus}
          alt="headerIllus"
          loading="lazy"
          style={{
            width: matches ? "100%" : "48vh",
            height: "auto",
            padding: 0,
            margin: 0,
          }}
        />
      </Box>
    </CustomBox>
  );
};

export default Header;
