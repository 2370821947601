import React, { useEffect, useState } from "react";
import {
  Card,
  Container,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { DataItemType } from "../Landing";
import { DataOrderCard } from "../../components";

function Checkout() {
  const location = useLocation();
  const dataCheckout: Array<DataItemType> = location?.state?.dataCheckout;
  const [totalPrice, setTotalPrice] = useState(0);
  const [uniqNumber, setUniqNumber] = useState(0);

  useEffect(() => {
    if (totalPrice === 0) {
      setTotalPrice((v) => {
        let rp = 0;
        dataCheckout.forEach((val) => {
          rp = rp + Number(val?.price);
        });

        console.log("rp", rp);
        return rp;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUniqNumber(Math.floor(Math.random() * (999 - 100 + 1) + 100));
  }, []);

  return (
    <Container
      maxWidth={"lg"}
      sx={{
        backgroundColor: "white",
        paddingTop: "8%",
        paddingBottom: "80px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Card
          sx={{
            backgroundColor: "white",
            paddingX: 4,
            paddingY: 2,
            border: "1px solid #023668",
            width: "100%",
            maxWidth: "800px",
          }}
        >
          <Typography variant="h3" textAlign={"center"} color="text">
            Ringkasan Order
          </Typography>
          <Typography
            variant="body2"
            textAlign={"center"}
            color="rgba(0,0,0,0.8)"
          >
            Periksa pesanan anda sebelum mengisi data order
          </Typography>
          <List sx={{ width: "100%" }}>
            {dataCheckout?.map((value, idx) => (
              <ListItem
                key={idx.toString()}
                disableGutters
                secondaryAction={
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        variant="body2"
                        color="text"
                        sx={{ fontWeight: "700" }}
                      >
                        Rp{value?.price}
                      </Typography>
                    }
                  />
                }
              >
                <ListItemText
                  disableTypography
                  primary={
                    <Typography
                      variant="body2"
                      style={{ maxWidth: "70%" }}
                      color="text"
                    >
                      {value?.title}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
            <ListItem
              disableGutters
              secondaryAction={
                <ListItemText
                  disableTypography
                  primary={
                    <Typography
                      variant="body2"
                      color="text"
                      sx={{ fontWeight: "700" }}
                    >
                      {uniqNumber}
                    </Typography>
                  }
                />
              }
            >
              <ListItemText
                disableTypography
                primary={
                  <Typography
                    variant="body2"
                    color="text"
                    sx={{ fontWeight: "700" }}
                  >
                    Nomer Unik Order
                  </Typography>
                }
              />
            </ListItem>
            <ListItem
              disableGutters
              secondaryAction={
                <ListItemText
                  disableTypography
                  primary={
                    <Typography variant="h5" color="text">
                      Rp{totalPrice}.{uniqNumber}
                    </Typography>
                  }
                />
              }
            >
              <ListItemText
                disableTypography
                primary={
                  <Typography variant="h5" color="text">
                    Total
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Card>
      </div>
      <DataOrderCard
        totalPrice={`Rp${totalPrice}.${uniqNumber}`}
        dataCheckout={dataCheckout}
      />
    </Container>
  );
}

export default Checkout;
