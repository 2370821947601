import React from "react";
import {
  Badge,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";

type ImageMediaCardProps = {
  img: string;
  title: string;
  desc: string;
  price: string;
  counter?: number;
  onAdd?: () => void;
  onDelete?: () => void;
};

const BadgeCustom = styled(Badge)(({ theme }) => ({
  zIndex: 99,
  right: -8,
  "& .MuiBadge-badge": {
    fontSize: "0.75rem",
    height: "28px",
    minWidth: "28px",
    borderRadius: 99,
  },
  marginBottom: 12,
  [theme.breakpoints.down("md")]: {
    right: 0,
  },
}));

function ImageMediaCard(props: ImageMediaCardProps) {
  const matches = useMediaQuery("(min-width:500px)");

  return (
    <BadgeCustom
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      badgeContent={props.counter}
      color="success"
    >
      <Card sx={{ maxWidth: 345 }}>
        <CardMedia
          component="img"
          alt={props.title}
          height="300"
          image={props.img}
          sx={{
            objectFit: "contain",
          }}
        />
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={7.5} md={7} sm={7}>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                textAlign="left"
              >
                {props.title}
              </Typography>
            </Grid>
            <Grid item xs={4.5} md={5} sm={5}>
              <Typography
                variant="body2"
                component="div"
                textAlign="left"
                sx={{
                  fontWeight: "700",
                  textAlign: "right",
                }}
              >
                Rp{props.price}
              </Typography>
            </Grid>
          </Grid>
          <Typography variant="body2" color="text.secondary" textAlign="left">
            {props.desc}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            onClick={props.onAdd}
            size="medium"
            sx={{
              backgroundColor: "#023668",
              color: "#ffffff",
              fontSize: "0.7rem",
              marginRight: "4px",
            }}
            style={{ cursor: "pointer" }}
          >
            Tambahkan
          </Button>
          <Button
            variant="contained"
            onClick={props.onDelete}
            size="medium"
            sx={{
              backgroundColor: "#c40c1e",
              color: "#ffffff",
              fontSize: "0.7rem",
              "&:focus": {
                backgroundColor: "#c40c1e",
              },
              "&:hover": {
                backgroundColor: "rgba(117, 13, 23, 0.9)",
              },
            }}
            style={{ cursor: "pointer" }}
          >
            Hapus
          </Button>
        </CardActions>
      </Card>
    </BadgeCustom>
  );
}

export default ImageMediaCard;
