import {
  Badge,
  Box,
  Button,
  Container,
  Fab,
  Grid,
  Link,
  Tabs,
  Typography,
  styled,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { Header, ImageMediaCard } from "../../components";
import DiningIcon from "@mui/icons-material/Dining";
import { useNavigate } from "react-router-dom";
import images from "../../assets/images";

export type DataItemType = {
  id: string;
  title: string;
  desc: string;
  price: string;
  img: string;
};

const listItemSedepedes = [
  {
    id: "0",
    title: "[PROMO] Bundling Lauk + Sambal",
    desc: "1 Botol Sambal 150gr + Lauk Pilihan-mu, jangan lupa tulis dicatatan form order pilihan lauknya!",
    price: "50.000",
    img: images.bundling,
  },
  {
    id: "1",
    title: "Sambal Teri Sedepedes",
    desc: "Sambal Teri khas Sedepedes 150gr",
    price: "30.000",
    img: images.sambel,
  },
  {
    id: "2",
    title: "Paru Sambal Ijo",
    desc: "Lauk Paru Sambal Ijo khas Sedepedes",
    price: "25.000",
    img: images.laukParu,
  },
  {
    id: "3",
    title: "Tongkol Suwir",
    desc: "Lauk Tongkol Suwir khas Sedepedes",
    price: "25.000",
    img: images.laukTongkol,
  },
  {
    id: "4",
    title: "Ayam Woku",
    desc: "Lauk Ayam Woku khas Sedepedes",
    price: "25.000",
    img: images.laukAyam,
  },
  {
    id: "5",
    title: "Sayur Daun Pepaya",
    desc: "Sayur Daun Pepaya Tumis khas Sedepedes",
    price: "25.000",
    img: images.laukDaun,
  },
  {
    id: "6",
    title: "Cumi Pedas",
    desc: "Lauk Cumi Tumis Pedas khas Sedepedes",
    price: "25.000",
    img: images.laukCumi,
  },
  {
    id: "7",
    title: "Udang Kentang Balado",
    desc: "Lauk Udang Kentang Balado khas Sedepedes",
    price: "25.000",
    img: images.laukUdang,
  },
];

const listItemKaluen = [
  {
    id: "8",
    title: "Kaluen Coco",
    desc: "Coco (Coconut Coffee) adalah Espresso dengan air kelapa dan gula aren",
    price: "15.000",
    img: images.kopiKaluen,
  },
  {
    id: "9",
    title: "Kaluen Amer",
    desc: "Amer (Americano) adalah Espresso dengan air tanpa gula",
    price: "15.000",
    img: images.kopiKaluen,
  },
  {
    id: "10",
    title: "Kaluen Kosu",
    desc: "Kosu (Kopi Susu) adalah Espresso dengan susu dan gula aren",
    price: "15.000",
    img: images.kopiKaluen,
  },
  {
    id: "11",
    title: "Kaluen Puding Cokelat",
    desc: "Puding Cokelat",
    price: "15.000",
    img: images.puding,
  },
  {
    id: "12",
    title: "Kaluen Puding Red Velvet",
    desc: "Puding Red Velvet",
    price: "15.000",
    img: images.puding,
  },
  {
    id: "13",
    title: "Kaluen Puding Mango Lychee",
    desc: "Puding Mango Lychee",
    price: "15.000",
    img: images.puding,
  },
  {
    id: "14",
    title: "Kaluen PeachGum (Serve)",
    desc: "Peach Gum, Angeo, Goji Berry, Dried Longan, Lotus Seed, Jamur Salju, Gula Batu, Manisan Kundur, Barley",
    price: "15.000",
    img: images.peachgumReady,
  },
  {
    id: "15",
    title: "Kaluen PeachGum (Pouch)",
    desc: "Peach Gum, Angeo, Goji Berry, Dried Longan, Lotus Seed, Jamur Salju, Gula Batu, Manisan Kundur, Barley",
    price: "20.000",
    img: images.peachgumPack,
  },
];

const listItemYume = [
  {
    id: "16",
    title: "Yume",
    desc: "Ingredients:\nFresh Strawberry, Oat, Water, Salt, Natural Honey",
    price: "25.000",
    img: images.yume,
  },
];

const listItemJamuSaha = [
  {
    id: "17",
    title: "Jamu Saha",
    desc: "Jamu Saha adalah Minuman all in one jamu yang terbuat dari rimpang segar pilihan Jahe - Kunyit - Asam Jawa - Sereh - Kencur - Gula Jawa",
    price: "20.000",
    img: images.jamuSaha,
  },
];

const FloatingButton = styled(Badge)(({ theme }) => ({
  zIndex: 9999,
  position: "fixed",
  bottom: theme.spacing(2),
  right: "20%",
  margin: "0px",
  top: "auto",
  left: "auto",
  "& .MuiBadge-badge": {
    fontSize: "0.75rem",
    height: "24px",
    minWidth: "24px",
    borderRadius: 99,
  },
  [theme.breakpoints.down("xl")]: {
    right: "10%",
  },
  [theme.breakpoints.down("md")]: {
    right: "6%",
  },
}));

function Landing() {
  const navigate = useNavigate();
  const [itemCheckout, setitemCheckout] = useState<Array<DataItemType>>([]);
  const [showCheckout, setshowCheckout] = useState(false);
  const [showList, setShowList] = useState("SEDEPEDES");
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const listBrand = ["SEDEPEDES", "KA.LU.ÈN", "JAMU SAHA", "YUME"];

  const CustomBox = styled(Box)(({ theme }) => ({
    minHeight: "60vh",
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(8),
    marginTop: "2vh",
    backgroundColor: "#003e7a",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
  }));

  const SecondBox = styled(Box)(({ theme }) => ({
    minHeight: "40vh",
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    paddingBottom: theme.spacing(2),
    marginTop: "4vh",
    backgroundColor: "#F6C82B",
    [theme.breakpoints.down("md")]: {
      // flexDirection: "column",
    },
  }));

  const refBuy = useRef<any>(null);
  const handleClickBuy = () => {
    refBuy.current?.scrollIntoView({ behavior: "smooth" });
  };
  const refAboutUs = useRef<any>(null);
  const handleClickAboutUs = () => {
    refAboutUs.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <Container
      maxWidth={"lg"}
      sx={{ backgroundColor: "white", alignItems: "center" }}
    >
      <Header onPressBuy={handleClickBuy} onPressAboutus={handleClickAboutUs} />
      <div ref={refBuy} style={{ paddingTop: 8 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          style={{
            cursor: "pointer",
          }}
        >
          {listBrand.map((v, i) => (
            <Button
              variant="outlined"
              onClick={() => {
                setShowList(v);
                setValue(i);
              }}
              style={{
                backgroundColor: v === showList ? "#023668" : "white",
                color: v === showList ? "white" : "#023668",
                border: "px solid",
                marginRight: "8px",
                minWidth: "12vh",
                cursor: "pointer",
                fontSize: "1.35vh",
                fontWeight: "600",
              }}
            >
              {v}
            </Button>
          ))}
        </Tabs>
      </div>
      <CustomBox>
        <div style={{ paddingRight: 2, paddingLeft: 2 }}>
          <Typography
            variant="h3"
            textAlign={"center"}
            color="white"
          ></Typography>
          <Typography variant="h3" textAlign={"center"} color="white">
            {showList}
          </Typography>
        </div>
        <Grid container spacing={2} sx={{ marginTop: "2vh" }}>
          {showList === "SEDEPEDES" &&
            listItemSedepedes.map((item, idx) => (
              <Grid key={idx.toString()} item xs={12} md={4} sm={6}>
                <ImageMediaCard
                  img={item?.img}
                  title={item?.title}
                  desc={item?.desc}
                  price={item?.price}
                  counter={
                    itemCheckout.filter((itm) => itm?.id === item?.id).length
                  }
                  onAdd={() => {
                    if (itemCheckout.length < 50) {
                      setitemCheckout((v) => [...v, item]);
                    }
                    setshowCheckout(true);
                  }}
                  onDelete={() => {
                    setitemCheckout((v) =>
                      v.filter((itm) => itm?.id !== item?.id)
                    );
                  }}
                />
              </Grid>
            ))}
          {showList === "KA.LU.ÈN" &&
            listItemKaluen.map((item, idx) => (
              <Grid key={idx.toString()} item xs={12} md={4} sm={6}>
                <ImageMediaCard
                  img={item?.img}
                  title={item?.title}
                  desc={item?.desc}
                  price={item?.price}
                  counter={
                    itemCheckout.filter((itm) => itm?.id === item?.id).length
                  }
                  onAdd={() => {
                    if (itemCheckout.length < 50) {
                      setitemCheckout((v) => [...v, item]);
                    }
                    setshowCheckout(true);
                  }}
                  onDelete={() => {
                    setitemCheckout((v) =>
                      v.filter((itm) => itm?.id !== item?.id)
                    );
                  }}
                />
              </Grid>
            ))}
          {showList === "JAMU SAHA" &&
            listItemJamuSaha.map((item, idx) => (
              <Grid key={idx.toString()} item xs={12} md={4} sm={6}>
                <ImageMediaCard
                  img={item?.img}
                  title={item?.title}
                  desc={item?.desc}
                  price={item?.price}
                  counter={
                    itemCheckout.filter((itm) => itm?.id === item?.id).length
                  }
                  onAdd={() => {
                    if (itemCheckout.length < 50) {
                      setitemCheckout((v) => [...v, item]);
                    }
                    setshowCheckout(true);
                  }}
                  onDelete={() => {
                    setitemCheckout((v) =>
                      v.filter((itm) => itm?.id !== item?.id)
                    );
                  }}
                />
              </Grid>
            ))}
          {showList === "YUME" &&
            listItemYume.map((item, idx) => (
              <Grid key={idx.toString()} item xs={12} md={4} sm={6}>
                <ImageMediaCard
                  img={item?.img}
                  title={item?.title}
                  desc={item?.desc}
                  price={item?.price}
                  counter={
                    itemCheckout.filter((itm) => itm?.id === item?.id).length
                  }
                  onAdd={() => {
                    if (itemCheckout.length < 50) {
                      setitemCheckout((v) => [...v, item]);
                    }
                    setshowCheckout(true);
                  }}
                  onDelete={() => {
                    setitemCheckout((v) =>
                      v.filter((itm) => itm?.id !== item?.id)
                    );
                  }}
                />
              </Grid>
            ))}
        </Grid>
      </CustomBox>

      <SecondBox ref={refAboutUs}>
        <div>
          <Typography
            variant="body1"
            component="p"
            textAlign="center"
            sx={{
              lineHeight: 1.6,
              fontWeight: "700",
            }}
          >
            ABOUT JAJANSEDEP
          </Typography>
          <Typography
            variant="body2"
            component="p"
            sx={{
              py: 2,
            }}
            textAlign={"justify"}
          >
            Selamat datang di Jajansedep, platform inovatif yang lahir dari
            kebutuhan kami sebagai mahasiswa PPM yang aktif beraktivitas pada
            hari Sabtu. Kami memahami bahwa keterbatasan waktu seringkali
            membuat sulit untuk menemukan jajanan yang beragam. Oleh karena itu,
            kami menciptakan Jajansedep, sebuah solusi untuk mempertemukan
            teman-teman yang mencari jajanan bervariasi dengan teman-teman yang
            memiliki bisnis jajanan. Nikmati kemudahan menemukan jajanan
            favoritmu di satu tempat! ;)
          </Typography>
        </div>
        <Box
          sx={{
            minHeight: "18vh",
            alignContent: "flex-end",
          }}
        >
          <Typography
            variant="body2"
            component="p"
            sx={{
              py: 0.5,
            }}
          >
            Whatsapp:{" "}
            <Link
              style={{ color: "#210af0" }}
              rel="noopener noreferrer"
              href="https://wa.me/6285281454300"
              target="_blank"
            >
              {" 085281454300 "}
            </Link>
          </Typography>
          <Typography
            variant="body2"
            component="p"
            sx={{
              py: 0.5,
            }}
          >
            Email: jajansedep26@gmail.com
          </Typography>
          <Typography
            variant="h6"
            component="p"
            sx={{
              py: 0.5,
              fontWeight: "500",
            }}
          >
            Kawan JAJAN-nya PPM Mates!
          </Typography>
        </Box>
      </SecondBox>

      {showCheckout && (
        <FloatingButton
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          badgeContent={itemCheckout.length}
          color="success"
        >
          <Fab
            onClick={() => {
              navigate("/checkout", { state: { dataCheckout: itemCheckout } });
            }}
            variant="extended"
            // size="medium"
            color={undefined}
            disabled={itemCheckout?.length <= 0}
            sx={{
              color: "white",
              backgroundColor: "#F6C82B",
              zIndex: 0,
              padding: 1,
              fontSize: "0.85rem",
              borderRadius: 3,
              "&:focus": {
                backgroundColor: "#F6C82B",
              },
              "&:disabled": {
                backgroundColor: "#dec395",
              },
            }}
          >
            <DiningIcon sx={{ mr: 1 }} />
            Periksa Jajanan
          </Fab>
        </FloatingButton>
      )}
    </Container>
  );
}

export default Landing;
